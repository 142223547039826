<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo1.png')"
          class="my-3"
          contain
          max-height="200"
        />
      </v-col>
    </v-row>

    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          <div>Welcome to GameDevTeam.net</div>
        </h1>

        <p class="subheading font-weight-regular">
          We are currently working on the site,
          <br />Please sign up to be ready for our launch
          <v-btn flat color="#ccc" router-link to="/in">Login or Signup</v-btn>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HomeContent",

  data: () => ({}),
};
</script>
