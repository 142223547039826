<template>
  <v-app dark>
    <v-main>
      <v-app-bar elevation="6" color="#1697f6">
        <v-app-bar-title style="color: #fff; font-weight: bold"><v-btn router-link to="/">GameDevTeam.net</v-btn></v-app-bar-title>
        <v-btn flat right="-70%" router-link to="/in">Login or Signup</v-btn>
        <v-btn flat right="-55%" router-link to="/About">About</v-btn>
      </v-app-bar>
      <v-content>
        <router-view />
      </v-content>
      <v-footer
        elevation="6"
        style="background-color: #1697f6; color: #fff"
        class="pa-4"
        bottom
        fixed
        width="100%"
      >
        <v-spacer></v-spacer>
        <div style="font-weight: bold">
          GameDevTeam.net &nbsp;&copy; {{ new Date().getFullYear() }}
        </div>
        <v-spacer></v-spacer>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>
::-webkit-scrollbar {
  display: none;
}
</style>
