<template>
  <div>
    <HomeContent />
  </div>
</template>

<script>
import HomeContent from "../components/HomeContent.vue";

export default {
  name: "Home",

  components: {
    HomeContent,
  },
};
</script>
